<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <!-- <b-card header="Prova Riassunto Dati Polizza" header-tag="header">
            <b-card-text>
              fields: {{ JSON.stringify(dtlFlds, null, 2) }} <br />
              detailsData: {{ JSON.stringify(detailsData, null, 2) }}<br />
              beFormData: {{ JSON.stringify(beFormData, null, 2) }}
            </b-card-text>
          </b-card> -->
          <div class="mb-2">
            <details-card
              v-for="(item, index) in dtlFlds"
              :key="index"
              :header="item.header"
              :fields="item.fields"
              :beForm="beFormData"
              :data="detailsData"
              :repository="item.repository"
            >
            </details-card>
          </div>
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].registry_id.label"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurer_id"
                        :name="beForm[rep].insurer_id.label"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].number.label"
                        vid="number"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero polizza"
                        :rules="getRules('number')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione libera"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="mandate_code_id"
                        :name="beForm[rep].mandate_code_id.label"
                        :label="beForm[rep].mandate_code_id.label"
                        :options="mandate_code_options"
                        v-model="form[rep].mandate_code_id"
                        placeholder="Seleziona un codice mandato"
                        :rules="{ required: true }"
                        :disabled="!form[rep].insurer_id"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Prodotto Assicurativo"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        name="Ramo"
                        vid="branch"
                        label="Ramo"
                        :options="risk_branches"
                        v-model="form.risk_branch_id"
                        :multiple="false"
                        @input="onInputBranch"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurance_risk_id"
                        :name="beForm[rep].insurance_risk_id.label"
                        :label="beForm[rep].insurance_risk_id.label"
                        v-model="form[rep].insurance_risk_id"
                        :options="prdct"
                        @select="onSelectRisk"
                        @remove="onRemoveRisk"
                        :rules="getRules('insurance_risk_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Date" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        :options="broker_options"
                        v-model="form[rep].broker_id"
                        :rules="getRules('broker_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="payment_splitting"
                        :name="beForm[rep].payment_splitting.label"
                        :label="beForm[rep].payment_splitting.label"
                        v-model="form[rep].payment_splitting"
                        :options="beForm[rep].payment_splitting.options"
                        :rules="getRules('payment_splitting')"
                        @select="onPaymentSplitting"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="status_policy"
                        :name="beForm[rep].status_policy.label"
                        :label="beForm[rep].status_policy.label"
                        v-model="form[rep].status_policy"
                        :options="status_policy_opt"
                        :rules="getRules('status_policy')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="issued_at"
                        :name="beForm[rep].issued_at.label"
                        :label="beForm[rep].issued_at.label"
                        v-model="form[rep].issued_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting)
                        "
                        :rules="getRules('issued_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting, true)
                        "
                        :rules="getRules('effective_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        ref="expired_at"
                        vid="expired_at"
                        :name="beForm[rep].expired_at.label"
                        :label="beForm[rep].expired_at.label"
                        v-model="form[rep].expired_at"
                        :rules="getRules('expired_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="covered_at"
                        :name="beForm[rep].covered_at.label"
                        :label="beForm[rep].covered_at.label"
                        v-model="form[rep].covered_at"
                        :rules="getRules('covered_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="suspended_at"
                        :name="beForm[rep].suspended_at.label"
                        :label="beForm[rep].suspended_at.label"
                        v-model="form[rep].suspended_at"
                        :rules="getRules('suspended_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="cancelled_at"
                        :name="beForm[rep].cancelled_at.label"
                        :label="beForm[rep].cancelled_at.label"
                        v-model="form[rep].cancelled_at"
                        :rules="getRules('cancelled_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_NONOT_value"
                        :name="beForm[rep].attribute_NONOT_value.label"
                        :label="beForm[rep].attribute_NONOT_value.label"
                        v-model="form[rep].attribute_NONOT_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_NONOT_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2">
                <b-card header="Premio" header-tag="header">
                  <div @click="onClickSwitch()" class="switch">
                    <b-form-checkbox
                      v-model="isBreakdown"
                      name="check-button"
                      switch
                      :disabled="
                        !form[rep].insurance_risk_id ||
                        breakdowns.length == 0 ||
                        isLoadingBreakdown
                      "
                      @change="onChangeSwitch()"
                    >
                      {{ isBreakdown ? "Scorpori Premio" : "Premio" }}
                    </b-form-checkbox>
                  </div>
                  <b-card-text v-if="!isBreakdown">
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-currency
                          :name="beForm[rep].net_premium.label"
                          vid="net_premium"
                          :label="beForm[rep].net_premium.label"
                          v-model="form[rep].net_premium"
                          :options="{
                            currency: 'EUR',
                            locale: 'it-IT',
                            precision: 2,
                          }"
                          :rules="getRules('net_premium')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-currency
                          name="Tasse"
                          vid="tax_premium"
                          label="Tasse"
                          v-model="form[rep].tax_premium"
                          :options="{
                            currency: 'EUR',
                            locale: 'it-IT',
                            precision: 2,
                          }"
                          :rules="getRules('tax_premium')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-input
                          :name="beForm[rep].gross_premium.label"
                          vid="gross_premium"
                          :label="beForm[rep].gross_premium.label"
                          v-model="grossPremiumSum"
                          :readonly="true"
                          custom_type="currency"
                          :rules="getRules('gross_premium')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                  <b-card-text v-if="isBreakdown">
                    <div v-if="breakdowns.length">
                      <b-overlay center :show="isLoadingBreakdown" rounded="sm">
                        <b-table
                          hover
                          :items="breakdowns"
                          :fields="fields"
                          ref="breakdowns"
                        >
                          <template v-slot:cell(net)="row">
                            <div class="form-group col-md-7">
                              <base-currency
                                :name="beForm[rep].net_premium.label"
                                :vid="`net-${row.item.id}`"
                                :label="beForm[rep].net_premium.label"
                                v-model="row.item.net.value"
                                :options="{
                                  currency: 'EUR',
                                  locale: 'it-IT',
                                  precision: 2,
                                }"
                                :readonly="
                                  row.item.net.is_net === 'Y' ? false : true
                                "
                              />
                            </div>
                          </template>
                          <template v-slot:cell(tax)="row">
                            <div class="form-group col-md-7">
                              <base-currency
                                name="Tasse"
                                :vid="`tax-${row.item.id}`"
                                label="Tasse"
                                v-model="row.item.tax.value"
                                :options="{
                                  currency: 'EUR',
                                  locale: 'it-IT',
                                  precision: 2,
                                }"
                                :readonly="
                                  row.item.tax.is_tax === 'Y' ? false : true
                                "
                              />
                            </div>
                          </template>
                          <template v-slot:cell(grossrow)="row">
                            <div class="form-group col-md-7">
                              <base-input
                                name="grossrow"
                                :vid="`grossrow-${row.item.id}`"
                                label="Lordo Riga"
                                custom_type="currency"
                                :value="
                                  grossRowBookEntry(
                                    row.item.tax.value,
                                    row.item.net.value
                                  )
                                "
                                :readonly="true"
                              />
                            </div>
                          </template>
                          <template slot="bottom-row">
                            <td>Totale</td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="net-sum-label"
                                  :value="netSum"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="tax-sum-label"
                                  :value="taxSum"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="gross-sum-label"
                                  :value="grossSum"
                                />
                              </div>
                            </td>
                          </template>
                        </b-table>
                        <template #overlay>
                          <div class="text-center">
                            <base-icon name="loading" width="35" height="35" />
                            <p id="cancel-label">Operazione in corso...</p>
                          </div>
                        </template>
                      </b-overlay>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseLabel from "@/components/form/BaseLabel";
import CustomInputs from "@/components/form/CustomInputs";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData /*, toRelationString */ } from "@/utils/transforms";
import { getRiskTypeDescription } from "@/utils/payments";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import FormMixin from "@/mixins/FormMixin";
import { calcPolicyDate, today } from "@/utils/dates";
import { mapGetters } from "vuex";
import { sumBreakdowns, dataMappingPremium } from "@/utils/breakdowns";
import DetailsCard from "@/components/DetailsCard";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin, StorageGetterMixin],
  data() {
    return {
      repository: "insurance_policy",
      isLoadingAjax: false,
      ajaxOptions: [],
      broker_options: [],
      mandate_code_options: [],
      companies: [],
      risk_branches: [],
      risks: [],
      products: [],
      status_policy_opt: [
        { value: 0, text: "Non Perfezionata" },
        { value: 1, text: "Perfezionata" },
        { value: 2, text: "Sospesa" },
      ],
      isBreakdown: false,
      isLoadingBreakdown: false,
      registry_data: null,
      breakdowns: [],
      fields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "net",
          label: "Netto",
        },
        {
          key: "tax",
          label: "Tasse",
        },
        {
          key: "grossRow",
          label: "Lordo Riga",
        },
      ],
      //----Breakdownables
      id: this.$route.params.id,
      form: {
        inpt_label: "",
        quick_value: "",
        risk_id: null,
        risk_branch_id: null,
        insurance_policy: {
          attribute_NONOT_value: "N",
          gross_premium: null,
          net_premium: 0.0,
          mandate_code_id: null,
          tax_premium: 0.0,
          number: null,
          broker_id: null,
          registry_id: null,
          insurer_id: null,
          insurance_policy_id: null,
          insurance_risk_id: null,
          status_policy: null,
          title: null,
          covered_at: null,
          effective_at: null,
          expired_at: null,
          issued_at: today(),
          cancelled_at: null,
          payment_splitting: null,
        },
        breakdown: {},
      },
      detailsData: this.$route.params.data.details,
      beFormData: this.$route.params.data.beForm,
      detailFields: {
        dati_polizza: {
          header: "Riassunto Dati Polizza",
          edit: false,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary("insurer_id", "insurance_policy"),
              value: "insurer.code",
            },
            {
              label: this.getDictionary("number", "insurance_policy"),
              value: "number",
            },
            {
              label: this.getDictionary("code", "risk_branch"), // "risk_branch.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "insurance_risk.risk_branch.code",
            },
            {
              label: this.getDictionary("risk_type", "insurance_risk"), // "risk_type.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "insurance_risk.code",
            },
            {
              label: this.getDictionary("salesman"),
              value: "broker",
              virtual: (detail) => {
                return detail.broker
                  ? this.toInfoData(detail.broker, "broker", null, " ")
                  : "";
              },
            },
            {
              label: this.getDictionary("Collaborazione orizzontale"),
              value: "",
            },
            {
              label: this.getDictionary("insurance_risk"), // "insurance_risk.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "",
              virtual: (detail) => {
                return this.getRiskTypeDescription(detail);
              },
            },
            {
              label: this.getDictionary("net_premium", "insurance_policy"),
              value: "net_premium",
              type: "currency",
            },
            {
              label: this.getDictionary("tax_premium", "insurance_policy"),
              value: "tax_premium",
              type: "currency",
            },
            {
              label: this.getDictionary("gross_premium", "insurance_policy"),
              value: "gross_premium",
              type: "currency",
            },
            {
              label: this.getDictionary(
                "payment_splitting",
                "insurance_policy"
              ),
              value: "payment_splitting.text",
            },
            {
              label: this.getDictionary("issued_at"),
              value: "issued_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("effective_at"),
              value: "effective_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("expired_at"),
              value: "expired_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("covered_at"),
              value: "covered_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("suspended_at", "insurance_policy"),
              value: "suspended_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary(
                "attribute_REVD_value",
                "insurance_policy"
              ),
              value: "attributables.REVD",
            },
            {
              label: this.getDictionary(
                "attribute_RENW_value",
                "insurance_policy"
              ),
              value: "attributables.RENW",
            },
            {
              label: this.getDictionary(
                "attribute_INDX_value",
                "insurance_policy"
              ),
              value: "attributables.INDX",
            },
            {
              label: this.getDictionary(
                "attribute_CONV_value",
                "insurance_policy"
              ),
              value: "attributables.CONV",
            },
            {
              label: this.getDictionary(
                "attribute_LNOT_value",
                "insurance_policy"
              ),
              value: "attributables.LNOT",
            },
            {
              label: this.getDictionary(
                "attribute_BONA_value",
                "insurance_policy"
              ),
              value: "attributables.BONA",
            },
            {
              label: this.getDictionary("delay_days", "insurance_policy"),
              value: "delay_days",
            },
            {
              label: this.getDictionary("status_policy", "insurance_policy"),
              value: "status_policy.text",
            },
            {
              label: this.getDictionary("old_policy", "insurance_policy"),
              value: "number",
              virtual: "OLD_POLICY",
              relation: "old_policy",
              isLink: true,
            },
            {
              label: this.getDictionary("new_policies", "insurance_policy"),
              value: "number",
              virtual: "NEW_POLICIES",
              relation: "new_policies",
              isLink: true,
            },
          ],
        },
        status_registry_1: {
          dati_anagrafici: {
            header: "Riassunto Dati Anagrafici del Contraente",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("attribute_CNAM_value", "registry"),
                value: "registry.attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "registry"),
                value: "registry.attributables.CREG",
              },
            ],
          },
        },
        status_registry_0: {
          dati_anagrafici: {
            header: "Riassunto Dati Anagrafici del Contraente",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("attribute_TITL_value", "registry"),
                value: "registry.attributables.TITL",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "registry"),
                value: "registry.attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "registry"),
                value: "registry.attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "registry.attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_MARS_value", "registry"),
                value: "registry.attributables.MARS",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "registry.attributables.PROF",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "registry.attributables.DOB",
              },
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                value: "registry.attributables.POB",
              },
              {
                label: `${this.getDictionary("province")} nascita`,
                value: "registry.attributables.POB",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("country"),
                value: "registry.attributables.POB",
                virtual: "NATION",
              },
              {
                label: this.getDictionary("attribute_ISPL_value", "registry"),
                value: "registry.attributables.ISPL",
              },
              {
                label: `${this.getDictionary("province")} emissione`,
                value: "registry.attributables.ISPL",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("attribute_SEX_value", "registry"),
                value: "registry.attributables.SEX",
              },
            ],
          },
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    CustomInputs,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchRegistryModal,
    BaseLabel,
    DetailsCard,
  },
  methods: {
    toInfoData,
    getRiskTypeDescription,
    // fetch() {
    //   let filter = `relations/byBroker/byRegistry/byInsurer/byInsuranceRisk/byNewPolicy/byOldPolicy`;
    //   let queryString = `${filter ? filter : ""}`;
    //   this.fetchShowForm(this.repository, this.resourceId, queryString)
    //     .then((response) => {
    //       const data = response.data.data;
    //       this.status_registry_value = data.registry.status_registry.value;
    //       this.details = data;
    //       this.isLoading = false;
    //       this.$emit("number", data.number);
    //     })
    //     .catch((error) => {
    //       this.form = {};
    //       console.error(error);
    //     })
    //     .finally(() => {});
    // },
    preFillForm() {
      /**
       * insurance_policy_id (la polizza da sostituire)
       * anagrafica
       * insurer
       * mandate_code_id
       * ramo
       * prodotto
       * giorni di mora
       * produttore
       * frazionamento
       * no notifica
       * data copertura
       * data scadenza
       */
      // 0. insurance_policy_id
      this.form[this.rep].insurance_policy_id = this.id;
      // 1. anagrafica
      this.registry_data = this.detailsData.registry;
      this.form[this.rep].registry_id = this.registry_data.id;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      // 2. insurer
      this.form[this.rep].insurer_id = this.detailsData.insurer.id;
      // 2.1 carica mandate_code
      this.mandate_code_options = this.getMandateCodes()(
        this.form[this.rep].insurer_id
      );
      // 3. descrizione
      this.form[this.rep].title = this.detailsData.title;
      // 4. mandate_code_id
      this.form[this.rep].mandate_code_id = this.detailsData.mandate_code_id;
      // 5. ramo
      this.form.risk_branch_id = this.detailsData.insurance_risk.risk_branch.id;
      // trigger
      this.onInputBranch(this.form.risk_branch_id);
      // 6. prodotto
      this.form[this.rep].insurance_risk_id =
        this.detailsData.insurance_risk.id;
      // 7. giorni di mora
      this.form[this.rep].delay_days =
        this.detailsData.insurance_risk.delay_days;
      // 8. produttore
      this.form[this.rep].broker_id = this.detailsData.broker.id;
      // 9. frazionamento
      this.form[this.rep].payment_splitting =
        this.detailsData.payment_splitting.value;
      // trigger
      this.onPaymentSplitting(this.form[this.rep].payment_splitting);
      // 10. status_policy (card 761)
      this.form[this.rep].status_policy = 0;
      // 11. no notifica
      this.form[this.rep].attribute_NONOT_value =
        this.detailsData.attributables.NONOT;
      // 12. Data Copertura
      this.form[this.rep].covered_at = this.detailsData.covered_at;
      // 13. Data Scadenza
      this.form[this.rep].expired_at = this.detailsData.expired_at;
    },
    onSubmit() {
      if (this.isBreakdown) {
        this.form[this.rep].net_premium = sumBreakdowns(this.breakdowns, "net");
        this.form[this.rep].gross_premium =
          sumBreakdowns(this.breakdowns, "net") +
          sumBreakdowns(this.breakdowns, "tax");
        this.form[this.rep].tax_premium = sumBreakdowns(this.breakdowns, "tax");
      } else {
        this.form[this.rep].gross_premium =
          this.form[this.rep].net_premium + this.form[this.rep].tax_premium;
      }
      this.isLoading = true;
      // const Repo = RepositoryFactory.get(this.repository);
      // Repo.store(this.form[this.repository])
      this.store(this.repository)
        .then((response) => {
          let insurance_policy_id = response.data.data.id;
          let insurance_ancillary =
            response.data.data.insurance_ancillaries.find(
              (insurance_ancillary) =>
                insurance_ancillary.insurance_policy_id === insurance_policy_id
            );
          if (this.isBreakdown && insurance_ancillary) {
            for (let i = 0; i < this.breakdowns.length; i++) {
              let breakdownId = this.breakdowns[i].id;
              this.form.breakdown[breakdownId] = {
                net: this.breakdowns[i].net.value,
                tax: this.breakdowns[i].tax.value,
              };
            }
            let stores = this.addBreakdownables(
              insurance_ancillary.title_id,
              "breakdown",
              {
                breakdown: this.form.breakdown,
              }
            );
            Promise.all([stores])
              .then(() => {
                //status_policy "perfezionata"
                this.onSuccess(insurance_policy_id);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.onSuccess(insurance_policy_id);
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSuccess(id) {
      this.showConfirm({
        yesCallback: () => {
          this.shortcut("module.PFOLIO", null, "#Policies", null, null, {
            click: "openModal",
            id: id,
            modalName: "modal",
            hash: "#General",
          });
        },
        noCallback: () => {
          this.shortcut("module.PFOLIO", null, "#Policies");
        },
        title: "Polizza creata",
        message: "Visualizzare il dettaglio?",
        yesLabel: "VISUALIZZA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    addBreakdownables(inusranceAncillaryId, relation, payload) {
      const Repo = RepositoryFactory.get("insurance_ancillary");
      Repo.pivot_store(inusranceAncillaryId, relation, payload);
    },
    onPaymentSplitting(val, skip = false) {
      // Data decorrenza = Data emissione
      if (!skip) {
        this.form[this.repository].effective_at =
          this.form[this.repository].issued_at;
      }
      // Data scadenza = Data decorrenza + 1 anno
      this.form[this.repository].expired_at = calcPolicyDate(
        this.form[this.repository].effective_at
      ).format("YYYY-MM-DD");
      // Data copertura = Data decorrenza + Frazionamento
      this.form[this.repository].covered_at = calcPolicyDate(
        this.form[this.repository].effective_at,
        val
      ).format("YYYY-MM-DD");
    },
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
      this.form.risk_branch_id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.form[this.rep].insurance_risk_id = null;
      this.form[this.rep].delay_days = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      // reset mandate_code_id
      this.form[this.rep].mandate_code_id = null;
      // carica (o svuota) la tendina dei mandati
      this.mandate_code_options = [];
      if (insurer) {
        this.mandate_code_options = this.getMandateCodes()(insurer);
      }
    },
    onInputBranch(branches) {
      this.form[this.rep].insurance_risk_id = null;
      this.form[this.rep].delay_days = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        branches,
        this.form.risk_id
      );
    },
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onRemoveRisk() {
      if (this.isBreakdown) {
        this.clearPremiumVModel();
        this.breakdowns = [];
        this.isBreakdown = this.isBreakdown ? false : false;
      }
      this.form[this.rep].delay_days = null;
    },
    onSelectRisk(value) {
      // Set Delay Days
      const selectedProduct = this.getProducts()().find(
        (product) => product.value === value
      );
      this.form[this.rep].delay_days = selectedProduct
        ? selectedProduct.delay_days
        : null;
      // Breakdowns
      this.isLoadingBreakdown = true;
      const Repo = RepositoryFactory.get("breakdown");
      let querystring = `byInsuranceRisk[id]=${value}`;
      Repo.index(querystring)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            this.breakdowns = dataMappingPremium(data);
          } else {
            this.isBreakdown = false;
            this.breakdowns = [];
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoadingBreakdown = false;
        });
    },
    onClickSwitch() {
      if (!this.form[this.rep].insurance_risk_id) {
        this.$showSnackbar({
          preset: "error",
          text: `Devi selezionare un prodotto per abilitare gli scorpori`,
        });
      }
    },
    onChangeSwitch() {
      this.clearPremiumVModel();
    },
    clearPremiumVModel() {
      if (!this.isBreakdown) {
        this.form[this.rep].net_premium = 0.0;
        this.form[this.rep].gross_premium = 0.0;
        this.form[this.rep].tax_premium = 0.0;
      }
    },
    reduceSum(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue[field].value;
      }, initialValue);
      return sum;
    },
    // toCurrency(value) {
    //   if (!value) value = "0.00001";
    //   return toLocaleCurrency(parseFloat(value));
    // },
    grossRowBookEntry(net, tax) {
      return toLocaleCurrency(net + tax);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getMandateCodes: "mandate_codes",
    }),
  },
  mounted() {
    // const companiesWithoutImporter = this.$store.state.auth.insurers
    //   .filter((e) => !e.importer_id)
    //   .map((e) => e.id);
    // this.companies = this.getInsurers().filter((e) =>
    //   companiesWithoutImporter.includes(e.value)
    // );
    this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
    this.risks = this.getRisks();
  },
  created() {
    this.isLoading = true;
    this.broker_options = this.getSalesmen();
    /* */
    console.log("beforeMount Settings");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("mandate_codes"))
      resources.push(
        this.$store
          .dispatch(`${path}mandate_codes`)
          .then(() => {
            console.info(
              `background data "${path}mandate_codes" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      const companiesWithoutImporter = this.$store.state.auth.insurers
        .filter((e) => !e.importer_id)
        .map((e) => e.id);
      this.companies = this.getInsurers().filter((e) =>
        companiesWithoutImporter.includes(e.value)
      );
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          const companiesWithoutImporter = this.$store.state.auth.insurers
            .filter((e) => !e.importer_id)
            .map((e) => e.id);
          this.companies = this.getInsurers().filter((e) =>
            companiesWithoutImporter.includes(e.value)
          );
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
    this.fetchCreateForm(this.repository).then(() => {
      this.ajaxOptions = [];
      this.preFillForm();
      this.isLoading = false;
    });
  },
  computed: {
    netSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "net"));
      },
    },
    taxSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "tax"));
      },
    },
    grossSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.breakdowns, "net") +
            sumBreakdowns(this.breakdowns, "tax")
        );
      },
    },
    grossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net_premium + this.form[this.rep].tax_premium
        );
      },
    },
    rep() {
      return this.repository;
    },
    prdct() {
      return this.products;
    },
    breakdownable() {
      return "breakdownable";
    },
    dtlFlds() {
      // mergia i fields di status 0 o 1 nei fields dati_polizza
      // let { status_registry_0, status_registry_1, ...rest } = this.detailFields;
      // let c = this.detailsData.registry.status_registry.value
      //   ? status_registry_1
      //   : status_registry_0;
      // let r = { ...c, ...rest };
      // disegno solo dati_polizza, tanto dati_anagrafici sono prefillati nel form sotto
      let r = { dati_polizza: this.detailFields.dati_polizza };
      console.log("r: ", r);
      return r;
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.registry_id {
  text-align: center;
}
.info {
  display: inline-block;
  margin: 10px 20px 15px 0;
  padding: 5px 10px;
}
p {
  margin-bottom: 0.5rem;
}
.switch {
  width: 70px;
}
</style>
