<template>
  <div class="mt-1 mx-3">
    <policies>
    </policies>
  </div>
</template>

<script>
import Policies from '@/components/wallet/policies/index.vue'
export default {
  props: {
  },
  components: {
    Policies
  },
  created() {
  }
}
</script>
